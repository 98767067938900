import React from 'react'
import PropTypes from 'prop-types'
import Barcode from 'react-barcode'
import { Button } from 'antd'

import './index.css'

const NavigateButton = ({ className, disabled, label, loading, onClick, position, qrAction }) => (
  <div className="navigateBtn">
    <Button
      block
      className={className}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      size={position === 'no-align' ? 'small' : 'large'}
      type="primary"
    >
      <div className="barcodeWrapper" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        {qrAction && <Barcode displayValue={false} value={qrAction} width={1} />}
        {label}
      </div>
    </Button>
  </div>
)

NavigateButton.defaultProps = {
  disabled: false,
  loading: false,
  position: 'align-right',
}

NavigateButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  position: PropTypes.string,
  qrAction: PropTypes.string,
}

export default NavigateButton
