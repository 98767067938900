import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Form,
  Input,
  Modal,
  Select,
} from 'antd'
import { compact } from 'lodash'
import { formPropTypes, companyPropTypes } from '../../types'

const { TextArea } = Input

const CompanyForm = ({
  errorMessage,
  filteredRetailers,
  isModalLoading,
  onSubmit,
  selectedCompany,
  setModalLoading,
  setVisibility,
}) => {
  const [form] = Form.useForm()
  const { getFieldsValue, isFieldsTouched } = form
  const isUpdate = !!selectedCompany

  const filteredRetailersWithResetOption = compact([
    (isUpdate && selectedCompany.retailer) && { id: null, name: 'Remove current retailer'},
    ...filteredRetailers,
  ])

  const handleOk = () => {
    const isFormUpdated = isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fieldsValue = getFieldsValue([
            'address',
            'description',
            'id',
            'name',
            'retailer_id',
          ])
          const actionType = isUpdate ? 'UPDATE' : 'CREATE'
          const id = isUpdate ? selectedCompany.id : undefined

          onSubmit(actionType, { ...fieldsValue, id })
        })
        .catch((error) => handleError(error))
    } else {
      setVisibility(false)
    }
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  const handleCancel = () => {
    setVisibility(false)
  }

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelCompanyBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updateCompanySelenium' : 'addCompanySelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={handleCancel}
      onOk={handleOk}
      open
      title={isUpdate ? 'Update company' : 'Add a new company'}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          initialValue={isUpdate ? selectedCompany.name : undefined}
          label="Company Name"
          name="name"
          rules={[
            {
              message: 'Please add a company name!',
              required: true,
            },
            {
              message: 'Company name has to be at least 4 characters long!',
              min: 4,
            },
          ]}
        >
          <Input maxLength={100} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? `${selectedCompany.retailer?.name || ''}` : ''}
          label="Retailer"
          name="retailer_id"
        >
          <Select
            filterOption={filterOption}
            options={filteredRetailersWithResetOption.map(retailer => ({ label: retailer.name, value: retailer.id }))}
            showSearch
          />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedCompany.address : undefined}
          label="Address"
          name="address"
          rules={[
            {
              message: 'Please add an address!',
              required: true,
            },
            {
              message: 'Address has to be at least 2 characters long!',
              min: 2,
            },
          ]}
        >
          <Input maxLength={100} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedCompany.description : undefined}
          label="Description"
          name="description"
        >
          <TextArea style={{ minHeight: 100 }} />
        </Form.Item>
      </Form>

      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

CompanyForm.propTypes = {
  errorMessage: PropTypes.string,
  filteredRetailers: PropTypes.array,
  form: formPropTypes,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedCompany: companyPropTypes,
  setModalLoading: PropTypes.func.isRequired,
  setVisibility: PropTypes.func,
}

export const CompanyModal = CompanyForm
