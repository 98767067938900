import PropTypes from 'prop-types'

export default PropTypes.shape({
  comment: PropTypes.string,
  condition: PropTypes.string,
  id: PropTypes.number,
  media: PropTypes.array,
  missing_label: PropTypes.bool,
  quantity: PropTypes.number,
  status: PropTypes.string,
  upc: PropTypes.string,
})
