import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Dropdown, Layout, Menu, message } from 'antd'
import { hasPermission, useAuth0 } from '../../../auth0'
import { MENU_ELEMENTS } from '../../constants'
import { routePropTypes } from '../../../_shared/types'
import logo from '../../../_shared/assets/images/loop-blue-logo.png'

const onClick = ({ item, key }) => {
  localStorage.setItem('tds-active-app', key)
  message.info(`Switched to ${item.props.name}`)
  window.location.reload()
}

const items = [
  {
    key: 'admin',
    label: 'Transactional Data System',
    name: 'Transactional Data System',
  },
  {
    key: 'checkin',
    label: 'Warehouse Check-in System',
    name: 'Warehouse Check-in System',
  },
]

const Sidebar = ({
  location: { pathname },
  userHasWarehousePermission,
}) => {
  const { Sider } = Layout
  const [isCollapse, setCollapse] = useState(false)

  const { permissions } = useAuth0()
  const userMenuItems = MENU_ELEMENTS.filter(menuItem => hasPermission(permissions, menuItem.permission))

  return (
    <Sider collapsed={isCollapse} collapsible onCollapse={() => setCollapse(!isCollapse)}>
      <div
        className="logoWrapper"
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        <Dropdown
          disabled={!userHasWarehousePermission}
          menu={{ items, onClick }}
          placement="bottom"
        >
          <img
            alt="Loop logo"
            src={logo}
            style={{
              cursor: 'pointer',
              height: '45px',
            }}
          />
        </Dropdown>
      </div>

      <Menu items={userMenuItems} key="user" mode="inline" selectedKeys={[pathname]} theme="dark" />
    </Sider>
  )
}

Sidebar.propTypes = {
  ...routePropTypes,
  userHasWarehousePermission: PropTypes.bool,
}

export default withRouter(Sidebar)
