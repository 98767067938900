import queryString from 'query-string'
import { getHeaders, apiResponseHandler } from '../../../_shared/api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/shipping_containers'

export const getPartners = () => fetch(
  '/api/admin/partners',
  { headers: getHeaders() },
)
  .then(apiResponseHandler)

export const getStores = () => fetch(
  '/api/admin/stores',
  { headers: getHeaders() },
)
  .then(apiResponseHandler)

export const verifyIdentityCode = ({ identity_code, partner_code }) => fetch(
  `/api/admin/shipping_containers/validate/${identity_code}?partner_code=${partner_code}`,
  {
    headers: getHeaders(),
  },
).then(apiResponseHandler)

export const getShippingContainers = ({
  arrivedAtFilter,
  conditionFilter,
  dataProcessingFilter,
  depositAllocationFilter,
  identityCode,
  itemsPerPage,
  page,
  partnerFilter,
  countryFilter,
  statusFilter,
  storeFilter,
  verificationFilter,
}) => {
  const fromDate = arrivedAtFilter[0] ? `${arrivedAtFilter[0]}-0:00:00` : arrivedAtFilter[0]
  const toDate = arrivedAtFilter[1] ? `${arrivedAtFilter[1]}-23:59:59` : arrivedAtFilter[1]

  const queryParams = queryString.stringify(
    {
      condition: conditionFilter,
      country: countryFilter,
      data_processing: dataProcessingFilter,
      deposit_allocation: depositAllocationFilter,
      from: fromDate,
      identity_code: identityCode,
      items_per_page: itemsPerPage,
      page,
      partner_code: partnerFilter,
      status: statusFilter,
      store_id: storeFilter,
      to: toDate,
      verification: verificationFilter,
    },
    {
      arrayFormat: 'bracket',
    },
  )
  const endpoint = `${ENDPOINT_BASE_URL}${queryParams ? `?${queryParams}` : ''}`

  return fetch(
    endpoint,
    { headers: getHeaders() },
  )
    .then(apiResponseHandler)
}

export const createShippingContainer = ({ fields }) => fetch(
  ENDPOINT_BASE_URL,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(),
    method: 'POST',
  },
).then(apiResponseHandler)

export const updateShippingContainer = ({ fields }) => fetch(
  `${ENDPOINT_BASE_URL}/${fields.id}`,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(),
    method: 'PUT',
  },
).then(apiResponseHandler)

export const deleteShippingContainer = ({ recordId }) => (
  fetch(`${ENDPOINT_BASE_URL}/${recordId}`, {
    headers: getHeaders(),
    method: 'DELETE',
  })
).then(apiResponseHandler)

export const setShippingContainerStatusToAllocated = ({ recordId }) => (
  fetch(`${ENDPOINT_BASE_URL}/${recordId}/manually_allocate`, {
    headers: getHeaders(),
    method: 'PUT',
  })
).then(apiResponseHandler)

export const setShippingContainerStatusToUnknown = ({ recordId }) => (
  fetch(`${ENDPOINT_BASE_URL}/${recordId}/manually_unknown`, {
    headers: getHeaders(),
    method: 'PUT',
  })
).then(apiResponseHandler)

export const importShippingContainers = ({ file }) => {
  const endpoint = `${ENDPOINT_BASE_URL}/import`

  const header = getHeaders()
  delete header['Content-Type']

  return fetch(endpoint, {
    body: file,
    headers: header,
    method: 'POST',
  })
    .then(apiResponseHandler)
}
