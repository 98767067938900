import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { Layout } from 'antd'
import { Profile } from '../_shared/components'
import { Sidebar } from './components'
import {
  CompaniesPage,
  Error404Page,
  HomePage,
  ShippingContainerDetailsPage,
  ShippingContainersPage,
  StoresPage,
  UploadMediaPage,
} from './pages'

const { Content, Header, Footer } = Layout

const AdminLayout = ({ barcodes, retailers, userHasWarehousePermission }) => (
  <Layout style={{ minHeight: '100vh' }}>
    <Sidebar userHasWarehousePermission={userHasWarehousePermission} />

    <Layout>
      <Header style={{ background: '#fff', padding: '0 16px' }}>
        <Profile />
      </Header>

      <Content style={{ margin: '16px' }}>
        <div
          style={{ background: '#fff', minHeight: 360, padding: 24 }}
        >
          <Switch>
            <Route component={HomePage} exact path="/" />

            <Route
              path="/shipping-containers/:id"
              render={(props) => <ShippingContainerDetailsPage {...props} barcodes={barcodes} />}
            />

            <Route
              component={ShippingContainersPage}
              exact
              path="/shipping-containers"
            />

            <Route
              exact
              path="/companies"
              render={(props) => <CompaniesPage {...props} retailers={retailers} />}
            />

            <Route
              component={StoresPage}
              exact
              path="/stores"
            />

            <Route
              component={UploadMediaPage}
              exact
              path="/upload-media"
            />

            <Route component={Error404Page} />
          </Switch>
        </div>
      </Content>

      <Footer style={{ textAlign: 'center' }}>Loop TDS ©{new Date().getFullYear()}</Footer>
    </Layout>
  </Layout>
)

AdminLayout.propTypes = {
  barcodes: PropTypes.object,
  retailers: PropTypes.array,
  userHasWarehousePermission: PropTypes.bool,
}

export default AdminLayout
