import React, { useRef, useState } from 'react'
import { useAsync } from 'react-async'
import { isEmpty } from 'lodash'
import {
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Spin,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { getStores } from '../api'
import { NavigateButton } from '../../../components'
import { normalizeValue } from '../../../pages/scan/Scan'

import { currentScanDetails } from '../../../Layout'

const StoreId = () => {
  const { t } = useTranslation()
  const storeIdRef = useRef(null)

  const [form] = Form.useForm()

  const [fieldError, setFieldError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [storesForSelect, setStoresForSelect] = useState([])

  const countryStores = useAsync({
    country_code: currentScanDetails.value.data.value[0].country,
    promiseFn: getStores,
  })

  if (countryStores.isLoading) {
    return (
      <Spin name="loadingSpinnerSelenium" tip="...">
        <div className="content" />
      </Spin>
    )
  }

  const selectCorrectStore = (store_id) => {
    currentScanDetails.value.data.value[1] = {
      store_id,
    }

    message.open({
      content: t('step3Success'),
      type: 'success',
    })

    currentScanDetails.value.step.value = 2
  }

  const checkData = () => {
    const currentStoreId = storeIdRef.current.input.value

    setErrorMessage(null)
    setFieldError(false)

    if (!currentStoreId || currentStoreId.includes('::loop::')) {
      form.resetFields()
      return false
    }

    const matchedStores = countryStores.data.stores.filter(({ external_id, id }) => (id.toString() === currentStoreId || external_id === currentStoreId))

    if (!isEmpty(matchedStores)) {
      if (matchedStores.length === 1) {
        selectCorrectStore(matchedStores[0].id)
      } else {
        setStoresForSelect(matchedStores)
        setIsModalOpen(true)
      }
    } else {
      setErrorMessage(t('step3ErrorStore'))
      setFieldError(true)
      message.open({
        content: t('step3ErrorStore'),
        type: 'error',
      })

      storeIdRef.current.focus()
    }
  }

  if (currentScanDetails.value.barCodeAction.value?.includes('::loop::checkData')) {
    setTimeout(() => {
      checkData()
    }, 200)

    currentScanDetails.value.barCodeAction.value = null
  }

  if (currentScanDetails.value.barCodeAction.value?.includes('::loop::selectCorrectStore')) {
    const index = parseInt(currentScanDetails.value.barCodeAction.value.split('-')[1], 10)

    setTimeout(() => {
      setTimeout(() => selectCorrectStore(storesForSelect[index].id), 100)
    }, 200)

    currentScanDetails.value.barCodeAction.value = null
  }

  return (
    <Row>
      <Col span={14}>
        <Form
          form={form}
          initialValues={{
            store_id: '',
          }}
          layout="vertical"
          size="large"
        >
          <Form.Item
            help={errorMessage}
            label={t('step3ScanStoreId')}
            name="store_id"
            normalize={normalizeValue}
            validateStatus={fieldError && 'error'}
          >
            <Input
              autoFocus
              onPressEnter={checkData}
              ref={storeIdRef}
              size="large"
            />
          </Form.Item>
        </Form>
      </Col>

      <Col span={10}>
        <NavigateButton
          disabled={currentScanDetails.value.stepIsLoading.value}
          label={t('next')}
          loading={currentScanDetails.value.stepIsLoading.value}
          onClick={checkData}
          qrAction="::loop::checkData"
        />
      </Col>

      {!isEmpty(storesForSelect) &&
        <Modal
          closeIcon={false}
          footer={[]}
          onCancel={() => {}}
          open={isModalOpen}
          title={t('step2MoreSTores')}
          width="80%"
        >
          <Row>
            <Col offset={1} span={11}>
              <NavigateButton
                label={storesForSelect[0].name}
                onClick={() => selectCorrectStore(storesForSelect[0].id)}
                qrAction="::loop::selectCorrectStore-0"
              />
            </Col>
            <Col span={9}>
              <NavigateButton
                label={storesForSelect[1].name}
                onClick={() => selectCorrectStore(storesForSelect[1].id)}
                qrAction="::loop::selectCorrectStore-1"
              />
            </Col>
          </Row>
        </Modal>
      }
    </Row>
  )
}

export default StoreId
