import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isEmpty} from 'lodash'
import {
  Button,
  Col,
  Flex,
  Popconfirm,
  Row,
  Typography,
} from 'antd'
import { NavigateButton } from '../'

import images from '../../assets/images/'

const Package = ({ pack, deletePackage = null, editPackage = null }) => {
  const { t } = useTranslation()

  return (
    <Row style={{ marginBottom: 8 }}>
      <Col className="packageList" span={24}>
        <Col className="quantityWrapper">
          {pack.quantity} x
        </Col>
        <Col className="infoBox">
          <Flex>
            <img
              alt="avatar"
              src={isEmpty(pack.product?.media)
                ? images.noImageBottle
                : pack.product?.media[0].small_url
              }
              style={{
                maxHeight: 150,
                maxWidth: 150,
              }}
            />
          </Flex>

          <Flex
            justify="space-between"
            style={{
              padding: 8,
            }}
            vertical
          >
            {pack.comment &&
              <h3 className="package-comment">
                {pack.comment}
              </h3>
            }

            <Typography>
              <b>{t('step4ProductBarcode')}:</b> {pack.upc}
            </Typography>

            <Typography>
              <b>{t('step4ProductName')}:</b> {pack.product.product_name}
            </Typography>

            <Typography>
              <b>{t('step4SKU')}:</b> {pack.product?.sku}
            </Typography>

            <Typography className={pack.condition === 'ok' ? 'okCondition' : 'broken'}>
              <b>{t('step4Condition')}:</b> {pack.condition}
            </Typography>

            <Typography className={pack.missing_label && 'missing'}>
              <b>{t('step4MissingLabel')}:</b> {pack.missing_label ? t('yes') : t('no')}
            </Typography>
          </Flex>

          <Flex
            justify="center"
            style={{
              marginLeft: 'auto',
              padding: 8,
              position: 'relative',
            }}
            vertical
          >
            <NavigateButton
              label={t('edit')}
              onClick={() => editPackage(pack.id)}
            />
            <Popconfirm
              cancelText={t('no')}
              okText={t('yes')}
              onConfirm={() => deletePackage(pack.id)}
              title={t('step4Delete', { qty: pack.quantity })}
            >
              <Button style={{ borderColor: 'red', marginTop: '20px' }} type="danger">{t('delete')}</Button>
            </Popconfirm>
          </Flex>
        </Col>
      </Col>
    </Row>
  )
}

Package.propTypes = {
  deletePackage: PropTypes.func,
  editPackage: PropTypes.func,
  pack: PropTypes.object,
}

export default Package
