import React from 'react'
import {
  Alert,
  Col,
  notification,
  Row,
  Typography,
  Upload,
} from 'antd'
import { Icon } from '../../../_shared/components'
import { createShippingContainerMedia } from './api'

const { Title } = Typography
const { Dragger } = Upload

const AlertDescription = () => (
  <div>
    <p>
      <b>Click or drag file(s) to this area to upload. Please note that</b>
    </p>

    <ul>
      <li>file names should have the following format:</li>
      <ul>
        <li>1 image per shipping container: <b>YYYYMMDD_ToteQRcode</b></li>
        <li>multiple images per shipping container: <b>YYYYMMDD_ToteQRcode_PictureNumber</b></li>
      </ul>
      <li>only .jpg, .jpeg, .png file types are accepted</li>
      <li>files will only be accepted if</li>
      <ul>
        <li>ToteQRCode in filename must match an existing shipping container</li>
        <li>status of shipping container should be <i>"arrived"</i> or <i>"processed"</i></li>
        <li>date in filename is accepted to be the same as the arrival date of the shipping container or the following day</li>
      </ul>
    </ul>
  </div>
)

const UploadMediaPage = () => {
  const handleMediaUpload = ({ file, onError, onSuccess }) => {
    createShippingContainerMedia({ file })
      .then((response) => {
        onSuccess()

        notification.open({
          description: response || 'Media file is created successfully.',
          duration: 3,
          icon: <Icon color="#52c41a" type="checkCircle" />,
          message: 'Success',
        })
      })
      .catch((error) => {
        notification.open({
          description: error.message || 'Something went wrong',
          duration: 5,
          icon: <Icon color="#f5222d" type="closeCircle" />,
          message: 'Failure',
        })

        onError(null, error.message)
      })
  }

  return (
    <div>
      <Title>Upload media</Title>

      <Row>
        <Col span={24}>
          <Alert
            description={<AlertDescription />}
            showIcon
            style={{ marginBottom: '10px' }}
            type="info"
          />

          <Dragger
            className="uploadMediaSelenium"
            customRequest={handleMediaUpload}
            multiple={true}
            name="file"
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Click or drag file(s) to this area to upload</p>
          </Dragger>
        </Col>
      </Row>
    </div>
  )
}

export default UploadMediaPage
