import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Form,
  Modal,
  Select,
} from 'antd'
import { formPropTypes } from '../../types'

const { Option } = Select

const StatusModalForm = ({
  errorMessage,
  isModalLoading,
  onSubmit,
  selectedShippingContainerId,
  setModalLoading,
  setVisibility,
}) => {
  const [form] = Form.useForm()
  const { getFieldValue, isFieldsTouched } = form

  const handleOk = () => {
    const isFormUpdated = isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const statusValue = getFieldValue('status')

          onSubmit(statusValue, selectedShippingContainerId)
        })
        .catch((error) => handleError(error))
    } else {
      setVisibility(false)
    }
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  const handleCancel = () => {
    setVisibility(false)
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelStatusModalBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: 'updateShippingContainerStatusSelenium' }}
      okText="Set"
      onCancel={handleCancel}
      onOk={handleOk}
      open
      title="Set shipping container status"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Status"
          name="status"
        >
          <Select>
            <Option name="statusOption" value="manually_allocated">Manually allocated</Option>
            <Option name="statusOption" value="manually_unknown">Manually unknown</Option>
          </Select>
        </Form.Item>
      </Form>

      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

StatusModalForm.propTypes = {
  errorMessage: PropTypes.string,
  form: formPropTypes,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedShippingContainerId: PropTypes.number,
  setModalLoading: PropTypes.func.isRequired,
  setVisibility: PropTypes.func,
}

export const StatusModal = StatusModalForm
