import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import isEmpty from 'lodash.isempty'
import {
  Alert,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
} from 'antd'
import { verifyIdentityCode } from './api'
import { formPropTypes, shippingContainerPropTypes } from '../../types'

const { Option } = Select

const ShippingContainerForm = ({
  errorMessage,
  isModalLoading,
  onSubmit,
  partners,
  selectedShippingContainer,
  setModalLoading,
  setVisibility,
  stores,
}) => {
  const [form] = Form.useForm()
  const [isValidIdentityCode, setIsValidIdentityCode] = useState(false)
  const [identityCodeCountry, setIdentityCodeCountry] = useState(selectedShippingContainer?.country || null)
  const { getFieldsValue, isFieldsTouched } = form
  const isUpdate = !!selectedShippingContainer

  const handleOk = (event) => {
    const isFormUpdated = isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fieldsValue = getFieldsValue([
            'identity_code',
            'condition',
            'seal_code',
            'store_id',
            'partner_code',
            'comment',
            'arrived_at',
          ])

          const actionType = isUpdate ? 'UPDATE' : 'CREATE'
          const id = isUpdate ? selectedShippingContainer.id : undefined

          onSubmit(actionType, { ...fieldsValue, id })
        })
        .catch((error) => handleError(error))
    } else {
      setVisibility(false)
    }
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  const handleCancel = () => {
    setVisibility(false)
  }

  const onVerify = async () => {
    setIdentityCodeCountry(null)
    setIsValidIdentityCode(false)
    const { identity_code, partner_code } = form.getFieldsValue(['identity_code','partner_code'])

    if (isEmpty(identity_code) || isEmpty(partner_code))
      return Promise.resolve()

    setModalLoading(true)

    const response = await verifyIdentityCode({ identity_code, partner_code })

    setModalLoading(false)

    if (response) {
      setIdentityCodeCountry(response.country)
      setIsValidIdentityCode(true)
      return Promise.resolve()
    }

    return Promise.reject('Invalid identity code')
  }

  const selectedPartnerCode = Form.useWatch('partner_code', form)

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelShippingContainerBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{
        disabled:  (!isValidIdentityCode && !isUpdate),
        name: isUpdate ? 'updateShippingContainerSelenium' : 'addShippingContainerSelenium',
      }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={handleCancel}
      onOk={handleOk}
      open
      title={isUpdate ? 'Update shipping container' : 'Add a new shipping container'}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          initialValue={isUpdate ? selectedShippingContainer.partner_code : undefined}
          label="Partner"
          name="partner_code"
          rules={[{ message: 'Please select partner code!', required: true }]}
        >
          <Select onChange={onVerify}>
            {partners.filter(({ country }) => (country === identityCodeCountry) || !identityCodeCountry).map(partner => (
              <Option
                key={partner.value}
                name="partnerCodeOption"
                value={partner.value}
              >
                {partner.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          hasFeedback
          initialValue={isUpdate ? selectedShippingContainer.identity_code : undefined}
          label="Identity code"
          name="identity_code"
          rules= {[
            () => ({
              validator() {
                if (isUpdate) {
                  return Promise.resolve()
                }

                return onVerify()
              },
            }),
            {
              message: 'Please add a shipping container identity code!',
              required: true,
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input disabled={isUpdate || !selectedPartnerCode} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedShippingContainer.condition : 'ok'}
          label="Condition"
          name="condition"
        >
          <Select>
            <Option value="incomplete">Incomplete</Option>
            <Option value="ok">OK</Option>
            <Option value="seal_damaged">Seal damaged</Option>
            <Option value="shipping_container_and_seal_damaged">Shipping container and seal damaged</Option>
            <Option value="shipping_container_damaged">Shipping container damaged</Option>
            <Option value="unprocessable">Unprocessable</Option>
          </Select>
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedShippingContainer.seal_code : undefined}
          label="Seal code"
          name="seal_code"
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedShippingContainer?.store?.id : undefined}
          label="Store name"
          name="store_id"
        >
          <Select>
            {stores.filter(({ country }) => (country === identityCodeCountry) || !identityCodeCountry).map(store => (
              <Option
                key={store.value}
                name="storeIdOption"
                value={store.value}
              >
                {store.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedShippingContainer.comment : undefined}
          label="Comment"
          name="comment"
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? dayjs(selectedShippingContainer.arrived_at) : (dayjs())}
          label="Arrived at"
          name="arrived_at"
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
          />
        </Form.Item>
      </Form>

      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

ShippingContainerForm.propTypes = {
  errorMessage: PropTypes.string,
  form: formPropTypes,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selectedShippingContainer: shippingContainerPropTypes,
  setModalLoading: PropTypes.func.isRequired,
  setVisibility: PropTypes.func,
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
}

export const ShippingContainerModal = ShippingContainerForm
