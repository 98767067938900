import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '../../_shared/components'

const menuElements = [
  {
    icon: <Icon type="dashboard" />,
    key: '/',
    label: (<Link to="/">Dashboard</Link>),
    name: 'dashboardSelenium',
    permission: [],
  },
  {
    icon: <Icon type="appstore" />,
    key: '/shipping-containers',
    label: (<Link to="/shipping-containers">Shipping containers</Link>),
    name:'shippingContainersSelenium',
    permission: ['read:shipping_containers'],
  },
  {
    icon: <Icon type="shopping" />,
    key: '/companies',
    label: (<Link to="/companies">Companies</Link>),
    name:'companiesSelenium',
    permission: ['read:companies'],
  },
  {
    icon: <Icon type="shop" />,
    key: '/stores',
    label: (<Link to="/stores">Stores</Link>),
    name:'storesSideMenuitemSelenium',
    permission: ['read:stores'],
  },
  {
    icon: <Icon type="fileAdd" />,
    key: '/upload-media',
    label: (<Link to="/upload-media">Upload media</Link>),
    name:'uploadMediaSelenium',
    permission: ['create:media'],
  },
]

export default menuElements
