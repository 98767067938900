import React from 'react'
import PropTypes from 'prop-types'
import { Layout, Spin } from 'antd'
import { useAuth0 } from '../../../auth0'

const AuthenticationLayer = ({ children }) => {
  const { isAuthenticated, loading } = useAuth0()

  if (loading) return (
    <Layout style={{ minHeight: '100vh', padding: '30px 50px' }}>
      <Spin name="loadingSpinnerSelenium" tip="Loading...">
        <div className="content" />
      </Spin>
    </Layout>
  )

  return isAuthenticated ? children : <Login />
}

AuthenticationLayer.propTypes = {
  children: PropTypes.node.isRequired,
}

const Login = () => {
  const { loginWithRedirect } = useAuth0()

  loginWithRedirect()

  return null
}

export default AuthenticationLayer
