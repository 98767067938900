import { getHeaders, apiResponseHandler } from '../../../_shared/api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/shipping_containers'

export const getPartners = () => fetch(
  '/api/admin/partners',
  { headers: getHeaders() },
).then(apiResponseHandler)

export const verifyQrCode = ({ identity_code, partner_code }) => fetch(
  `/api/admin/shipping_containers/validate/${identity_code}?partner_code=${partner_code}`,
  {
    headers: getHeaders(),
  },
).then(apiResponseHandler)

export const getStores = ({ country_code }) => fetch(
  `/api/admin/stores?country=${country_code}`,
  { headers: getHeaders() },
).then(apiResponseHandler)

export const getShippingContainer = (shippingContainerId) => fetch(
  `/api/admin/shipping_containers/${shippingContainerId}`,
  { headers: getHeaders() },
).then(apiResponseHandler)

export const getShippingContainerPackages = (shippingContainerId) => fetch(
  `/api/admin/shipping_containers/${shippingContainerId}/packages`,
  { headers: getHeaders() },
).then(apiResponseHandler)

export const deleteShippingContainerPackage = (shippingContainerId, packageId) => fetch(
  `/api/admin/shipping_containers/${shippingContainerId}/packages/${packageId}`,
  {
    headers: getHeaders(),
    method: 'DELETE',
  },
).then(apiResponseHandler)

export const updateShippingContainerPackage = (shippingContainerId, packageId, fields) => fetch(
  `/api/admin/shipping_containers/${shippingContainerId}/packages/${packageId}`,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(),
    method: 'PUT',
  },
).then(apiResponseHandler)

export const createShippingContainerPackage = (shippingContainerId, fields) => fetch(
  `/api/admin/shipping_containers/${shippingContainerId}/packages`,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(),
    method: 'POST',
  },
).then(apiResponseHandler)

export const createShippingContainer = ({ fields }) => fetch(
  ENDPOINT_BASE_URL,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(),
    method: 'POST',
  },
).then(apiResponseHandler)

export const setShippingContainerStatus = ({ action, backendTimeFieldName, shippingContainerId }) => fetch(
  `${ENDPOINT_BASE_URL}/${shippingContainerId}/${action}`,
  {
    body: JSON.stringify({
      [backendTimeFieldName]: new Date().toISOString(),
      comment: action,
    }),
    headers: getHeaders(),
    method: 'PUT',
  },
).then(apiResponseHandler)

export const updateShippingContainer = (shippingContainerId, fields) => fetch(
  `/api/admin/shipping_containers/${shippingContainerId}`,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(),
    method: 'PUT',
  },
).then(apiResponseHandler)
