import PropTypes from 'prop-types'

export default PropTypes.shape({
  arrived_at: PropTypes.string,
  comment: PropTypes.string,
  condition: PropTypes.string,
  id: PropTypes.number,
  identity_code: PropTypes.string,
  media: PropTypes.array,
  partner_code: PropTypes.string,
  processed_at: PropTypes.string,
  quantity_of_packages: PropTypes.number,
  seal_code: PropTypes.string,
  sent_back_at: PropTypes.string,
  status: PropTypes.string,
})
