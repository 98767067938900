import React, { useState } from 'react'
import { useAsync } from 'react-async'
import queryString from 'query-string'
import compact from 'lodash.compact'
import flatten from 'lodash.flatten'
import isEmpty from 'lodash.isempty'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash.uniqby'
import {
  Button,
  Col,
  Empty,
  notification,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  Typography,
} from 'antd'
import {
  createStore,
  deleteStore,
  getCompanies,
  getPartners,
  getStores,
  updateStore,
} from './api'
import { Can, ErrorMessage, Icon } from '../../../_shared/components'
import { routePropTypes } from '../../../_shared/types'
import { getTableHeaderCheckboxFilter, getTableHeaderSearchInput } from '../../utils'
import { StoreModal } from './StoreModal'
import './Stores.css'

const convertToFlatArray = (variable) => compact(flatten([variable]))

const Stores = ({ history, location }) => {
  const { Title } = Typography

  const [isStoreModalVisible, setStoreModalVisibility] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [selectedStore, setSelectedStore] = useState(null)

  const queryParams = queryString.parse(location.search)
  const itemsPerPage = parseInt(queryParams.items_per_page, 10) || 25
  const page = parseInt(queryParams.page, 10) || 1
  const countryFilter = convertToFlatArray(queryParams.country)
  const ownerFilter = convertToFlatArray(queryParams.owner_id)
  const companyFilter = convertToFlatArray(queryParams.company_id)

  const { data, error, isLoading, reload } = useAsync({
    city: queryParams.city,
    companyFilter,
    countryFilter,
    itemsPerPage,
    name: queryParams.name,
    ownerFilter,
    page,
    promiseFn: getStores,
    state: queryParams.state,
    watch: location.search,
    zip: queryParams.zip,
  })

  const partners = useAsync({
    promiseFn: getPartners,
  })

  const companies = useAsync({
    promiseFn: getCompanies,
  })

  const uniqCountries = uniqBy(partners.data, 'country')

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data} />

  const handleDeleteStore = (recordId) => {
    deleteStore({ recordId })
      .then(() => handleSuccess({ description: 'Store removed successfully.' }, true))
      .catch((error) => handleFailure(error, { description: 'Store removal failed.' }))
  }

  const handleSuccess = (messageOptions = {}, isDeleted = false ) => {
    if (isDeleted) {
      history.push('/stores?page=1')
    } else {
      reload()
    }

    setStoreModalVisibility(false)
    setErrorMessage(null)

    notification.open({
      description: 'Store added successfully',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    console.log(error)
    setErrorMessage(error.message)
    setModalLoading(false)

    notification.open({
      description: 'Store creation failed.',
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'CREATE':
      return createStore({ fields: payload })
        .then(() => handleSuccess())
        .catch((error) => handleFailure(error))

    case 'UPDATE':
      return updateStore({ fields: payload })
        .then(() => handleSuccess({ description: 'Store updated successfully.' }))
        .catch((error) => handleFailure(error, { description: 'Store update failed.' }))

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleOpenModal = ({ selectedStore, setModalVisibility }) => {
    setSelectedStore(selectedStore)
    setErrorMessage(null)
    setModalVisibility(true)
    setModalLoading(false)
  }

  const handleTableChange = (pagination, filters) => {
    const queryStringObject = {
      ...filters,
      items_per_page: pagination.pageSize,
      page: pagination.current,
    }

    history.push({
      ...location,
      search: queryString.stringify(queryStringObject),
    })
  }

  const stores = ((data && data.stores) || []).map(store => {
    return {
      ...store,
      company: companies?.data?.companies.find(company => company.id === store.company_id)?.name,
      owner: companies?.data?.companies.find(company => company.id === store.owner_id)?.name,
    }
  })

  const totalItems = (data && data.pagination_meta.total_items) || 0

  const countryFilters = uniqCountries ? orderBy(uniqCountries.map(partner => ({ text: partner.country, value: partner.country })), 'text', 'asc')
    : []

  const companyList = companies ? orderBy((companies?.data?.companies ?? []).map(company => ({ text: company.name, value: company.id })), 'text', 'asc')
    : []

  const tableColumns = [
    {
      className: 'idSelenium storeIdWithExternalId',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => (
        <span>
          <p>{record.id}</p>
          {record.external_id &&
            <Tooltip title="External ID">
              <p>({record.external_id})</p>
            </Tooltip>
          }
        </span>
      ),
      title: 'ID (External ID)',
    },
    {
      className: 'nameSelenium storeNameSelenium',
      dataIndex: 'name',
      filteredValue: queryParams.name,
      key: 'name',
      title: 'Store Name',
      ...getTableHeaderSearchInput('storeNameSearchSelenium'),
    },
    {
      className: 'countrySelenium',
      dataIndex: 'country',
      filteredValue: isEmpty(countryFilter) ? undefined : countryFilter,
      key: 'country',
      title: 'Country',
      ...getTableHeaderCheckboxFilter('countryCodeFilterSelenium', countryFilters),
    },
    {
      className: 'stateSelenium',
      dataIndex: 'state',
      filteredValue: queryParams.state,
      key: 'state',
      title: 'State',
      ...getTableHeaderSearchInput('storeStateSearchSelenium'),
    },
    {
      className: 'citySelenium',
      dataIndex: 'city',
      filteredValue: queryParams.city,
      key: 'city',
      title: 'City',
      ...getTableHeaderSearchInput('storeCitySearchSelenium'),
    },
    {
      className: 'zipSelenium',
      dataIndex: 'zip',
      filteredValue: queryParams.zip,
      key: 'zip',
      title: 'Zip',
      ...getTableHeaderSearchInput('storeZipSearchSelenium'),
    },
    {
      className: 'addressSelenium storeAddressSelenium',
      dataIndex: 'address',
      key: 'address',
      title: 'Street Address',
    },
    {
      className: 'latitudeSelenium',
      dataIndex: 'latitude',
      key: 'latitude',
      title: 'GPS lat',
    },
    {
      className: 'longitudeSelenium',
      dataIndex: 'longitude',
      key: 'longitude',
      title: 'GPS long',
    },
    {
      className: 'areaSelenium',
      dataIndex: 'area',
      key: 'area',
      title: 'Store Area(sqft)',
    },
    {
      className: 'numberOfShippingContainersSelenium',
      dataIndex: 'number_of_shipping_containers',
      key: 'number_of_shipping_containers',
      title: 'Number of shipping containers',
    },
    {
      className: 'companySelenium',
      dataIndex: 'company',
      filteredValue: isEmpty(companyFilter) ? undefined : companyFilter,
      key: 'company_id',
      title: 'Company (retailer/brand)',
      ...getTableHeaderCheckboxFilter('companyFilterSelenium', companyList),
    },
    {
      className: 'ownerSelenium',
      dataIndex: 'owner',
      filteredValue: isEmpty(ownerFilter) ? undefined : ownerFilter,
      key: 'owner_id',
      title: 'Owner (franchise/corporate)',
      ...getTableHeaderCheckboxFilter('ownerFilterSelenium', companyList),
    },
    {
      className: 'commentSelenium',
      dataIndex: 'description',
      key: 'description',
      title: 'Comment',
      width: '250px',
    },
    {
      className: 'actionSelenium',
      dataIndex: 'operation',
      key: 'operation',
      render: (_, record) => (
        <span className="action-list">
          <Can
            requiredPermission="update:stores"
            yes={() => (
              <Button
                className="action-list-button"
                name="updateStoreSelenium"
                onClick={() => handleOpenModal({
                  selectedStore: record,
                  setModalVisibility: setStoreModalVisibility,
                })}
                size="small"
              >
                Update
              </Button>
            )}
          />
          <Can
            requiredPermission="delete:stores"
            yes={() => (
              <Popconfirm
                cancelText="No"
                className="action-list-button"
                disabled={record.number_of_shipping_containers > 0}
                okText="Yes"
                onConfirm={() => handleDeleteStore(record.id)}
                title="Are you sure you want to delete this store?"
              >
                <Button
                  danger
                  disabled={record.number_of_shipping_containers > 0}
                  ghost
                  name="deleteStoreSelenium"
                  size="small"
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          />
        </span>
      ),
      title: 'Action',
    },
  ]

  return (
    <div>
      <Title>Stores</Title>

      <Row>
        <Col span={24} style={{ marginBottom: '10px', textAlign: 'right' }}>
          <Can
            requiredPermission="create:stores"
            yes={() => (
              <Button
                name="addNewStoreSelenium"
                onClick={() => handleOpenModal({
                  selectedStore: null,
                  setModalVisibility: setStoreModalVisibility,
                })}
                type="primary"
              >
                Add
              </Button>
            )}
          />
        </Col>

        <Col span={24}>
          <Table
            bordered
            className="storeTableSelenium"
            columns={tableColumns}
            dataSource={stores}
            loading={isLoading}
            locale={{ emptyText: <Empty description="No stores" /> }}
            onChange={handleTableChange}
            pagination={{
              current: page,
              pageSize: itemsPerPage,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true,
              total: totalItems,
            }}
            rowKey="id"
            tableLayout="auto"
          />
        </Col>
      </Row>

      {isStoreModalVisible && (
        <StoreModal
          companyList={companyList}
          countryList={countryFilters}
          errorMessage={errorMessage}
          isModalLoading={isModalLoading}
          onSubmit={handleFormSubmit}
          selectedStore={selectedStore}
          setModalLoading={setModalLoading}
          setVisibility={setStoreModalVisibility}
        />
      )}
    </div>
  )
}

Stores.propTypes = {
  ...routePropTypes,
}

export default Stores

