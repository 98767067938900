import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Barcode from 'react-barcode'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { findLastKey } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
} from 'antd'
import { createShippingContainer, setShippingContainerStatus, verifyQrCode } from '../api'
import { NavigateButton } from '../../../components'
import { normalizeValue, loadShippingContainer } from '../../../pages/scan/Scan'

import images from '../../../assets/images'

import { currentScanDetails } from '../../../Layout'

dayjs.extend(utc)

const barCodeActionToCondition = {
  ic: 'incomplete',
  ok: 'ok',
  scd: 'shipping_container_damaged',
  up: 'unprocessable',
}

const QrCodeAndDetails = ({ barcodes }) => {
  const { t } = useTranslation()
  const qrCodeTagRef = useRef(null)
  const [condition, setCondition] = useState('ok')
  const [fieldError, setFieldError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [qrCode, setQrCode] = useState('')

  const [form] = Form.useForm()

  const qrCodeOnChange = useCallback((event) => {
    const { value } = event.target

    setQrCode(value.replace('::loop::checkQrCode', ''))
  }, [])

  const goNextStepAfterSavingDraft = useCallback((identity_code, data) => {
    const fieldsValue = form.getFieldsValue([
      'condition',
      'comment',
    ])
    const currentTime = dayjs.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss')

    const hasAutoBag = !!data.coms_container?.type?.sku

    const fieldsForDraft = {
      arrived_at: currentTime,
      comment: fieldsValue.comment,
      condition:fieldsValue.condition,
      data_processing: 'raw_data',
      identity_code,
      packages: [],
      partner_code: currentScanDetails.value.data.value[0].code,
      store_id: currentScanDetails.value.data.value[1].store_id,
    }

    if (hasAutoBag) {
      const bagBarcode = findLastKey(barcodes[currentScanDetails.value.data.value[0].country], (barcode) => barcode.sku === data.coms_container?.type?.sku)

      if (bagBarcode)
        fieldsForDraft.packages.push({
          ...barcodes[currentScanDetails.value.data.value[0].country][bagBarcode],
          comment: t('autoPackageComment'),
          condition: fieldsValue.condition,
          quantity: 1,
          upc: bagBarcode,
        })
    }

    createShippingContainer({ fields: fieldsForDraft })
      .then((draftData) => {
        currentScanDetails.value.data.value[2] = {
          comment: fieldsValue.comment,
          coms_container: data.coms_container,
          condition: fieldsValue.condition,
          id: draftData.id,
          identity_code,
        }

        message.open({
          content: t('step2QrCodeValid'),
          type: 'success',
        })

        currentScanDetails.value.stepIsLoading.value = false
        currentScanDetails.value.step.value = 3
      })
      .catch(error => {
        console.log(error.message)

        message.open({
          content: error.message,
          type: 'error',
        })

        currentScanDetails.value.stepIsLoading.value = false
      })
  }, [barcodes, form, t])

  const checkQrCode = useCallback(() => {
    if (!qrCode || qrCode.includes('condition::')) return false

    setErrorMessage(null)

    const identity_code = qrCode
    const partner_code = currentScanDetails.value.data.value[0].code

    if (identity_code === '' || identity_code.length < 4) {
      setErrorMessage(t('step2ErrorQrCode'))
      setFieldError(true)

      message.open({
        content: t('step2ErrorQrCode'),
        type: 'error',
      })

      return false
    }

    currentScanDetails.value.stepIsLoading.value = true

    verifyQrCode({ identity_code, partner_code })
      .then((data) => {
        if (!data) {
          setErrorMessage(t('step2ErrorQrCode'))
          setFieldError(true)

          message.open({
            content: t('step2ErrorQrCode'),
            type: 'error',
          })

          currentScanDetails.value.stepIsLoading.value = false

          qrCodeTagRef.current.focus()

          return false
        }

        if (!data.id) { //new shiping container
          goNextStepAfterSavingDraft(identity_code, data)
        } else if (data.id && data.status === 'arrived' && data.data_processing === 'processed_data') { // already saved and not draft
          setErrorMessage(t('step3AlreadyExistsError', { code: identity_code }))

          currentScanDetails.value.stepIsLoading.value = false
        } else if (data.id && data.status === 'processed') { // already saved but not arrived yet
          currentScanDetails.value.data.value[2] = {
            containerId: data.id,
          }

          setIsModalOpen(true)
          currentScanDetails.value.stepIsLoading.value = false
        } else if (data.id && data.data_processing === 'raw_data') { // already saved and draft
          loadShippingContainer(data.id, data.coms_container)

          message.open({
            content:  t('loadedFromReturn'),
            duration: 0,
            type: 'info',
          })
        }
      })
      .catch((error) => {
        setErrorMessage(t('step2ErrorQrCode'))
        setFieldError(true)

        message.open({
          content: error.message || t('step2ErrorQrCode'),
          type: 'error',
        })

        currentScanDetails.value.stepIsLoading.value = false
      })
  }, [goNextStepAfterSavingDraft, qrCode, t])

  const goBackToStore = () => {
    currentScanDetails.value.step.value = 1
    currentScanDetails.value.stepIsLoading.value = false
  }

  const sendBackRetailer = () => {
    currentScanDetails.value.stepIsLoading.value = true

    setShippingContainerStatus({
      action: 'send_back',
      backendTimeFieldName: 'sent_back_at',
      shippingContainerId: currentScanDetails.value.data.value[2].containerId,
    })
      .then(() => {
        currentScanDetails.value.stepIsLoading.value = false
        currentScanDetails.value.step.value = 4
        message.open({
          content: t('statusChanged'),
          type: 'success',
        })
      })
      .catch((error) => {
        currentScanDetails.value.stepIsLoading.value = false
        message.open({
          content: t('Error'),
          type: 'error',
        })
      })
  }

  if (currentScanDetails.value.barCodeAction.value?.includes('::loop::checkQrCode')) {
    setTimeout(() => {
      checkQrCode()
    }, 200)
    currentScanDetails.value.barCodeAction.value = null
  }

  if (currentScanDetails.value.barCodeAction.value?.includes('::loop::goBackToStore')) {
    setTimeout(() => {
      goBackToStore()
    }, 200)
    currentScanDetails.value.barCodeAction.value = null
  }

  if (currentScanDetails.value.barCodeAction.value?.includes('::loop::sendBackRetailer')) {
    setTimeout(() => {
      sendBackRetailer()
    }, 200)
    currentScanDetails.value.barCodeAction.value = null
  }

  if (currentScanDetails.value?.barCodeAction?.value?.includes('condition::')) {
    const newCondition = currentScanDetails.value.barCodeAction.value.split('::')[1]

    setTimeout(() => {
      form.setFieldValue('condition', barCodeActionToCondition[newCondition])
      setCondition(barCodeActionToCondition[newCondition])

      qrCodeTagRef.current.focus()
    }, 200)

    currentScanDetails.value.barCodeAction.value = null
  }

  return (
    <Row>
      <Col span={14} style={{ zIndex:1 }}>
        <Form
          form={form}
          initialValues={{
            comment: '',
            condition: 'ok',
            store_id: currentScanDetails.value.data.value[2].store_id || '',
          }}
          layout="vertical"
          size="large"
        >
          <Form.Item
            help={errorMessage}
            label={t('scanQrCode')}
            name="qrCode"
            normalize={normalizeValue}
            validateStatus={fieldError && 'error'}
          >
            <Input
              autoFocus
              onChange={qrCodeOnChange}
              onPressEnter={(e) => checkQrCode(e)}
              ref={qrCodeTagRef}
              size="large"
            />
          </Form.Item>

          <Col className="infoBox" span={24}>
            <Col className="imageWrapper">
              <img alt="Scan QR code at the bag" src={images.tote} />
              <img alt="Scan QR code at the bottle" src={images.bottle} />
            </Col>
            <Col>
              <ul>
                <li>{t('step2Help1')}</li>
                <li className="bold">{t('step2Help2')}:

                  <ul>
                    <li>{t('step2Help2_1')}</li>
                    <li>{t('step2Help2_2')}</li>
                  </ul>
                </li>
              </ul>
            </Col>
          </Col>

          <Form.Item label={t('step3Condition')} name="condition" style={{ marginTop: 60 }}>
            <Radio.Group className="conditionBtn" onChange={e => setCondition(e.target.value)}>
              <Radio.Button className={`default ${condition === 'ok' ? 'ok-button' : ''}`} value="ok">
                <Barcode displayValue={false} value="condition::ok" width={1} />
                {t('conditionOK')}
              </Radio.Button>

              <Radio.Button value="incomplete">
                <Barcode displayValue={false} value="condition::ic" width={1} />
                {t('conditionIncomplete')}
              </Radio.Button>

              <Radio.Button value="shipping_container_damaged">
                <Barcode displayValue={false} value="condition::scd" width={1} />
                {t('conditionShippingContainerDamaged')}
              </Radio.Button>

              <Radio.Button value="unprocessable">
                <Barcode displayValue={false} value="condition::up" width={1} />
                {t('conditionUnprocessable')}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          {condition !== 'ok' && (
            <Col className="infoBox conditionInfo">
              <Col>
                {condition === 'incomplete' && <><h3>{t('conditionIncomplete')}</h3><p>{t('conditionMoreInfoIncomplete')}</p></>}
                {condition === 'shipping_container_damaged' && <><h3>{t('conditionShippingContainerDamaged')}</h3><p>{t('conditionMoreInfoDamaged')}</p></>}
                {condition === 'unprocessable' && <><h3>{t('conditionUnprocessable')}</h3><p>{t('conditionMoreInfoUnprocessable')}</p></>}
              </Col>
            </Col>
          )}

          <Form.Item label={t('step3Comment')} name="comment" normalize={normalizeValue}>
            <Input.TextArea rows={4} size="large" />
          </Form.Item>

        </Form>
      </Col>
      <Col span={10}>
        <NavigateButton
          disabled={(qrCode.length < 4) || currentScanDetails.value.stepIsLoading.value}
          label={t('next')}
          loading={currentScanDetails.value.stepIsLoading.value}
          onClick={() => checkQrCode()}
          qrAction="::loop::checkQrCode"
        />
      </Col>

      <Modal
        closeIcon={false}
        footer={[]}
        onCancel={() => {}}
        open={isModalOpen}
        title={t('alreadyUsed')}
        width="80%"
      >
        <Row>
          <Col offset={4} span={8}>
            <NavigateButton
              disabled={currentScanDetails.value.stepIsLoading.value}
              label={t('cancel')}
              onClick={() => goBackToStore()}
              qrAction="::loop::goBackToStore"
            />
          </Col>
          <Col span={8}>
            <NavigateButton
              disabled={currentScanDetails.value.stepIsLoading.value}
              label={t('sendBack')}
              onClick={() => sendBackRetailer()}
              qrAction="::loop::sendBackRetailer"
            />
          </Col>
        </Row>
      </Modal>
    </Row>
  )
}

QrCodeAndDetails.propTypes = {
  barcodes: PropTypes.object,
}

export default QrCodeAndDetails
