import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, DatePicker, Input } from 'antd'
import { CalendarOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker

export const getTableHeaderDatePicker = (name) => {
  const handleReset = (filter) => {
    filter.clearFilters()
    filter.confirm()
  }

  return {
    filterDropdown: (filter) => (
      <div className={name} name="datePickerFilter" style={{ padding: 8 }}>
        <RangePicker
          allowClear={false}
          onChange={(_, dateString) => filter.setSelectedKeys(dateString)}
          style={{ marginBottom: 8, width: 300 }}
          value={[dayjs(filter.selectedKeys[0]), dayjs(filter.selectedKeys[1])]}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span
            className="ant-btn ant-btn-link ant-btn-sm"
            name="datePickerButtonResetSelenium"
            onClick={() => handleReset(filter)}
            size="small"
          >
            Reset
          </span>
          <Button
            name="datePickerButtonSearchSelenium"
            onClick={() => filter.confirm({ closeDropdown: true })}
            size="small"
            style={{ marginLeft: '20px' }}
            type="primary"
          >
            Search
          </Button>
        </div>
      </div>
    ),
    filterIcon: <CalendarOutlined className={`${name}_icon`} name={name} />,
  }
}

export const getTableHeaderSearchInput = (name) => {
  const handleReset = (filter) => {
    filter.clearFilters()
    filter.confirm()
  }

  return {
    filterDropdown: (filter) => (
      <div className={name} name="customFilterWrapper" style={{ padding: 8 }}>
        <Input
          onChange={e => filter.setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => filter.confirm({ closeDropdown: true })}
          placeholder="Search"
          style={{ display: 'block', marginBottom: 8, width: 188 }}
          value={filter.selectedKeys}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span
            className="ant-btn ant-btn-link ant-btn-sm"
            disabled={!filter.selectedKeys[0]}
            name="searchFilterResetButtonSelenium"
            onClick={() => handleReset(filter)}
            size="small"
          >
            Reset
          </span>
          <Button
            name="searchFilterButtonSelenium"
            onClick={() => filter.confirm({ closeDropdown: true })}
            size="small"
            style={{ marginLeft: '20px' }}
            type="primary"
          >
            Search
          </Button>
        </div>
      </div>
    ),
    filterIcon: <SearchOutlined className={`${name}_icon`} name={name} />,
  }
}

export const CheckboxFilter = ({filter, filters, name}) => {
  let initialFilters = {}
  let clearedFilters = {}

  filters.forEach(f => {
    initialFilters[f.value] = { ...f, selected: !!~filter.selectedKeys.indexOf(f.value.toString()) }
    clearedFilters[f.value] = { ...f, selected: false }
  })

  const [filterState, setFilterState] = useState(initialFilters)

  const handleReset = (filter) => {
    setFilterState({ ...clearedFilters })
    filter.clearFilters()
    filter.confirm()
  }

  const handleSelect = (filter, e) => {
    filterState[e.target.name].selected = !filterState[e.target.name].selected
    const tempKeys = Object.values(filterState).filter(elem => elem.selected).map(el => el.value)
    filter.setSelectedKeys(tempKeys)
  }

  return (
    <div name={`customFilterWrapper ant-table-filter-dropdown ${name}`}>
      <ul className={`${name}_searchFilter ant-dropdown-menu ant-dropdown-menu-without-submenu ant-dropdown-menu-root ant-dropdown-menu-vertical`}>
        {(filters ?? []).map(option =>
          <li className="ant-dropdown-menu-item" key={option.value} role="menuitem">
            <Checkbox checked={filterState[option.value]?.selected} name={option.value.toString()} onChange={e => handleSelect(filter, e)}>{option.text}</Checkbox>
          </li>,
        )}
      </ul>
      <div className="ant-table-filter-dropdown-btns" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span
          className="ant-btn ant-btn-link ant-btn-sm"
          disabled={!filter.selectedKeys[0]}
          name="searchFilterResetButtonSelenium"
          onClick={() => handleReset(filter)}
          size="small"
        >
          Reset
        </span>
        <Button
          className={`${name}_searchFilterButtonSelenium`}
          name="searchFilterButtonSelenium"
          onClick={() => filter.confirm({ closeDropdown: true })}
          size="small"
          style={{ marginLeft: '20px' }}
          type="primary"
        >
          Search
        </Button>
      </div>
    </div>
  )
}

CheckboxFilter.propTypes = {
  filter: PropTypes.object,
  filters: PropTypes.array,
  name: PropTypes.string,
}

export const getTableHeaderCheckboxFilter = (name, filters) => {
  if (filters.length > 0)
    return {
      filterDropdown: (filter) => (
        <CheckboxFilter filter={filter} filters={filters} name={name} />
      ),
      filterIcon: <FilterOutlined className={`${name}_icon`} name={name} />,
    }
}

export default getTableHeaderDatePicker
