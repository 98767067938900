import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import AdminLayout from './admin/Layout'
import CheckInLayout from './check-in/Layout'

import { useAuth0, hasPermission } from './auth0'

const App = ({ barcodes, retailers }) => {
  const { getTokenSilently, logout, permissions } = useAuth0()

  const userHasAdminPermission = hasPermission(permissions, ['tds_access_mode:admin'])
  const userHasWarehousePermission = hasPermission(permissions, ['tds_access_mode:checkin'])

  useEffect(() => {
    getTokenSilently().then(accessToken => localStorage.setItem('accessToken', accessToken))
  }, [getTokenSilently])

  const activeApp = localStorage.getItem('tds-active-app')
    ? localStorage.getItem('tds-active-app')
    : userHasAdminPermission
      ? 'admin'
      : userHasWarehousePermission
        ?'checkin'
        : null

  if (!activeApp) {
    logout()
    return <>Something went wrong! Please try again later</>
  }

  if (activeApp === 'admin')
    return <AdminLayout barcodes={barcodes} retailers={retailers} userHasWarehousePermission={userHasWarehousePermission} />

  if (activeApp === 'checkin')
    return <CheckInLayout barcodes={barcodes} userHasAdminPermission={userHasAdminPermission} />
}

App.propTypes = {
  barcodes: PropTypes.object,
  retailers: PropTypes.array,
}

export default App
