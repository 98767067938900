import tote from './loop_tote.png'
import bottle from './qrsticker.png'
import noImageBottle from './no-image-bottle.jpeg'
import noImagePack from './no-image-pack.jpeg'
import enFlag from './english.png'
import frFlag from './france.png'

const images = {
  bottle,
  enFlag,
  frFlag,
  noImageBottle,
  noImagePack,
  tote,
}

export default images
