import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { signal } from '@preact/signals-react'
import { Route, Switch } from 'react-router-dom'
import { FloatButton, Layout } from 'antd'
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons'
import { Profile } from '../_shared/components'
import { PartnerWidget, ScannerActions, SideBar } from './components'
import { ScanPage } from './pages'

import translations from './constants/locales'

const { Content, Header, Footer } = Layout

export const currentScanDetails = signal({
  barCodeAction: signal(null),
  data: signal([
    {
      code: null,
      country: null,
    },
    {
      store_id: null,
    },
    {
      comment: null,
      condition: 'ok',
      identity_code: null,
    },
  ]),
  step: signal(0),
  stepIsLoading: signal(false),
  widget: signal({
    code: null,
    country: null,
  }),
})

const activeLocale = localStorage.getItem('activeLocale') || 'en'

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lng: activeLocale,
    resources: {
      en: {
        translation: translations.en,
      },
      fr: {
        translation: translations.fr,
      },
    },
  })

const CheckInLayout = ({ barcodes, userHasAdminPermission }) => {
  const [isFullScreen, setIsFullScreem] = useState(false)

  const changefullScreen = () => {
    if (isFullScreen)
      document.exitFullscreen()
    else
      document.body.requestFullscreen()

    setIsFullScreem(!isFullScreen)
  }

  return (
    <Layout className="checkinPage" style={{ minHeight: '100vh' }}>
      <SideBar barcodes={barcodes} userHasAdminPermission={userHasAdminPermission} />

      <Layout>
        <Header style={{ background: '#fff', padding: '0 16px' }}>
          <Profile widget={<PartnerWidget />} />
        </Header>

        <Content style={{ margin: '16px' }}>
          <div
            style={{
              background: '#fff',
              minHeight: 640,
              padding: 12,
              position: 'relative',
            }}
          >
            <Switch>
              <Route
                exact
                path="*"
                render={() => <ScanPage barcodes={barcodes} />}
              />
            </Switch>
          </div>
        </Content>

        <Footer style={{ textAlign: 'center' }}>Loop TDS ©{new Date().getFullYear()}</Footer>

        <FloatButton
          icon={isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
          onClick={changefullScreen}
        />
      </Layout>

      <ScannerActions />
    </Layout>
  )
}

CheckInLayout.propTypes = {
  barcodes: PropTypes.object,
  userHasAdminPermission: PropTypes.bool,
}

export default CheckInLayout
