import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Row, Spin } from 'antd'
import { useAuth0 } from '../../../auth0'
import { Icon } from '../'

const Profile = () => {
  const { isAuthenticated, loading, loginWithRedirect, logout, user } = useAuth0()

  if (loading) return (
    <Spin name="loadingSpinnerSelenium" tip="Loading...">
      <div className="content" />
    </Spin>
  )

  if (!isAuthenticated) {
    return (
      <Button name="loginButtonSelenium" onClick={() => loginWithRedirect({})}>
        Log in
      </Button>
    )
  }

  return (
    <Col>
      <span style={{ color: '#999', marginRight: 4 }}>
        Hi,
      </span>

      <span name="usernameSelenium" style={{ marginRight: 10 }}>
        {user.nickname || user.name || user.email}
      </span>

      <span
        className="signOut"
        name="signOutBtnSelenium"
        onClick={() => {
          localStorage.removeItem('tds-active-app')
          localStorage.removeItem('checkin_step1_country')
          localStorage.removeItem('checkin_step1_partnerCode')
          logout()
        }}
        style={{ color: '#999', marginRight: 4 }}
      >
        <Icon type="logOut" />
        Sign out
      </span>
    </Col>
  )
}

const ProfileWithWrapper = (props) => (
  <>
    <Row align="middle" justify={props.widget ? 'space-between' : 'end'} style={{ height: '100%' }} type="flex">
      {props.widget &&
        <Col>
          {props.widget}
        </Col>
      }
      <Col>
        <Profile {...props} />
      </Col>
    </Row>
  </>
)

ProfileWithWrapper.propTypes = {
  widget: PropTypes.node,
}

export default ProfileWithWrapper
