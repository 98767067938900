import { apiResponseHandler, getHeaders } from '../../../_shared/api-utils'
import { ENDPOINT_BASE_URL } from '../shipping-containers/api'

export const getShippingContainerDetails = ({ shippingContainerId }) => {
  const endpoint = `${ENDPOINT_BASE_URL}/${shippingContainerId}`

  return fetch(
    endpoint,
    { headers: getHeaders() },
  )
    .then(apiResponseHandler)
}

export const getShippingContainerChangelog = ({ shippingContainerId }) => {
  const endpoint = `${ENDPOINT_BASE_URL}/${shippingContainerId}/history`

  return fetch(
    endpoint,
    { headers: getHeaders() },
  )
    .then(apiResponseHandler)
}

export const getShippingContainerPackages = ({ shippingContainerId }) => {
  const endpoint = `${ENDPOINT_BASE_URL}/${shippingContainerId}/packages`

  return fetch(
    endpoint,
    { headers: getHeaders() },
  )
    .then(apiResponseHandler)
}

export const createPackage = ({ fields, shippingContainerId }) => {
  const endpoint = `${ENDPOINT_BASE_URL}/${shippingContainerId}/packages`

  return fetch(
    endpoint,
    {
      body: JSON.stringify({
        ...fields,
      }),
      headers: getHeaders(),
      method: 'POST',
    },
  )
    .then(apiResponseHandler)
}

export const updatePackage = ({ fields, shippingContainerId }) => fetch(
  `${ENDPOINT_BASE_URL}/${shippingContainerId}/packages/${fields.id}`,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(),
    method: 'PUT',
  },
).then(apiResponseHandler)

export const deletePackage = ({ recordId, shippingContainerId }) => (
  fetch(`${ENDPOINT_BASE_URL}/${shippingContainerId}/packages/${recordId}`, {
    headers: getHeaders(),
    method: 'DELETE',
  })
).then(apiResponseHandler)

export const createShippingContainerMedia = ({ media, shippingContainerId }) => {
  const formData = new FormData()

  formData.append('media', media.file)

  return fetch(
    `${ENDPOINT_BASE_URL}/${shippingContainerId}/media`,
    {
      body: formData,
      headers: getHeaders(),
      method: 'POST',
    },
  ).then(apiResponseHandler)
}

export const deleteShippingContainerMedia = ({ name, shippingContainerId }) => fetch(
  `${ENDPOINT_BASE_URL}/${shippingContainerId}/media`,
  {
    body: JSON.stringify({
      filename: name,
    }),
    headers: getHeaders(),
    method: 'DELETE',
  },
).then(apiResponseHandler)

export const createPackageMedia = ({ media, packageId, shippingContainerId }) => {
  const formData = new FormData()
  formData.append('media', media.file)

  return fetch(
    `${ENDPOINT_BASE_URL}/${shippingContainerId}/packages/${packageId}/media`,
    {
      body: formData,
      headers: getHeaders(),
      method: 'POST',
    },
  ).then(apiResponseHandler)
}

export const deletePackageMedia = ({ name, packageId, shippingContainerId }) => fetch(
  `${ENDPOINT_BASE_URL}/${shippingContainerId}/packages/${packageId}/media`,
  {
    body: JSON.stringify({
      filename: name,
    }),
    headers: getHeaders(),
    method: 'DELETE',
  },
).then(apiResponseHandler)

export const cancelShippingContainer = ({ action, shippingContainerId }) => fetch(
  `${ENDPOINT_BASE_URL}/${shippingContainerId}/${action}`,
  {
    headers: getHeaders(),
    method: 'PUT',
  },
).then(apiResponseHandler)

export const setShippingContainerStatus = ({ action, backendTimeFieldName, shippingContainerId }) => fetch(
  `${ENDPOINT_BASE_URL}/${shippingContainerId}/${action}`,
  {
    body: JSON.stringify({
      [backendTimeFieldName]: new Date().toISOString(),
      comment: action,
    }),
    headers: getHeaders(),
    method: 'PUT',
  },
).then(apiResponseHandler)
