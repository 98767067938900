import PropTypes from 'prop-types'

export default PropTypes.shape({
  address: PropTypes.string,
  area: PropTypes.number,
  city: PropTypes.string,
  company_id: PropTypes.number,
  country: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  name: PropTypes.string,
  number_of_shipping_containers: PropTypes.number,
  owner_id: PropTypes.number,
  state: PropTypes.string,
  zip: PropTypes.string,
})
