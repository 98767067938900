import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Barcode from 'react-barcode'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Typography,
} from 'antd'
import { updateShippingContainerPackage, createShippingContainerPackage } from '../../api'
import { normalizeValue } from '../../../../pages/scan/Scan'
import { NavigateButton } from '../../../../components'

import images from '../../../../assets/images/'

import { currentScanDetails } from '../../../../Layout'

dayjs.extend(utc)

const barCodeActionToCondition = {
  dmg: 'damaged',
  dmgic: 'damaged_incomplete',
  ic: 'incomplete',
  ok: 'ok',
}

const AddProductModal = ({ isScan, isVisible, onCancelCallback, onSaveCallback, product, selectedPackage }) => {
  const { t } = useTranslation()
  const isUpdate = !!selectedPackage
  const [form] = Form.useForm()
  const labelStatus = Form.useWatch('missing_label', form)

  const [condition, setCondition] = useState('ok')

  const setQty = (value) => {
    const quantity = form.getFieldValue('quantity')
    const newValue = quantity + value

    if (newValue > 0)
      form.setFieldValue('quantity', newValue)
  }

  const addToList = () => {
    const {comment, condition, missing_label, quantity} = form.getFieldsValue('comment', 'condition', 'missing_label', 'quantity')

    const fields = {
      comment,
      condition,
      metadata: { input_type: isScan ? 'scanned_input' : 'manual_input' },
      missing_label,
      product: product.sku,
      quantity,
      upc: product.upc,
    }

    if (isUpdate) {
      updateShippingContainerPackage(currentScanDetails.value.data.value[2].id, selectedPackage.id, fields)
        .then(data => {
          message.open({
            content: t('packageSaved'),
            type: 'success',
          })

          onSaveCallback()
        })
        .catch(error => {
          message.open({
            content: error.message,
            type: 'error',
          })
        })
        .finally(() => {
          currentScanDetails.value.stepIsLoading.value = false
        })
    } else {
      createShippingContainerPackage(currentScanDetails.value.data.value[2].id, fields)
        .then(data => {
          message.open({
            content: t('packageSaved'),
            type: 'success',
          })

          onSaveCallback()
        })
        .catch(error => {
          message.open({
            content: error.message,
            type: 'error',
          })
        })
        .finally(() => {
          currentScanDetails.value.stepIsLoading.value = false
        })
    }
  }

  const onCancel = () => {
    form.resetFields()

    onCancelCallback()
  }

  const action = currentScanDetails.value.barCodeAction.value

  if (action) {
    switch (action) {
    case '::loop::cancelBarcodeModal':
      setTimeout(onCancel, 100)
      break
    case '::loop::addToList':
      setTimeout(addToList, 100)
      break
    case '::loop::QtyDecrease':
      setTimeout(() => setQty(-1), 100)
      break
    case '::loop::QtyIncrease':
      setTimeout(() => setQty(1), 100)
      break
    default:
      if (currentScanDetails.value?.barCodeAction?.value?.includes('condition::')) {
        const newCondition = currentScanDetails.value.barCodeAction.value.split('::')[1]

        setTimeout(() => {
          form.setFieldValue('condition', barCodeActionToCondition[newCondition])
          setCondition(barCodeActionToCondition[newCondition])
        }, 200)

      }
    }

    currentScanDetails.value.barCodeAction.value = null
  }

  return (
    <Modal
      className="barcodeModal"
      closeIcon={false}
      footer={null}
      open={isVisible}
      style={{
        position: 'relative',
      }}
      width="100%"
    >
      <Row style={{ position: 'initial' }}>
        <Col span={24}>
          <Form
            form={form}
            initialValues={{
              comment: selectedPackage?.comment || '',
              condition: selectedPackage?.condition || 'ok',
              missing_label: selectedPackage?.missing_label || false,
              quantity: selectedPackage?.quantity || 1,
            }}
            layout="vertical"
            size="large"
          >
            <Row>
              <Col span={12}>
                <Col className="infoBox">
                  <Flex justify="space-between" vertical>
                    <img
                      alt="avatar"
                      src={isEmpty(product?.media) ? images.noImageBottle : product?.media[0].small_url}
                      style={{
                        maxWidth: 300,
                      }}
                    />
                    <Flex
                      align="center"
                      className="productInfo"
                      justify="center"
                    >
                      <Flex vertical>
                        <Typography level={5}>
                          <b>{t('step4ProductBarcode')}:</b>
                        </Typography>

                        <Typography level={5}>
                          <b>{t('step4ProductName')}:</b>
                        </Typography>

                        <Typography level={5}>
                          <b>{t('step4SKU')}:</b>
                        </Typography>
                      </Flex>

                      <Flex
                        style={{
                          marginLeft:'5px',
                        }}
                        vertical
                      >
                        <Typography level={5}>
                          {product?.upc}
                        </Typography>

                        <Typography level={5}>
                          {product?.product_name}
                        </Typography>

                        <Typography level={5}>
                          {product?.sku}
                        </Typography>
                      </Flex>
                    </Flex>
                  </Flex>
                </Col>
              </Col>
              <Col offset={1} span={11}>
                <Row justify="center">
                  <Col>
                    <NavigateButton
                      label={isUpdate ? t('updatePackage') : t('addToList')}
                      loading={currentScanDetails.value.stepIsLoading.value}
                      onClick={() => addToList()}
                      qrAction="::loop::addToList"
                    />
                  </Col>
                </Row>
                <Row
                  align="middle"
                  className="quantityWrapper"
                  justify="center"
                  style={{
                    marginTop: 30,
                    position: 'relative',
                  }}
                >
                  <Col>
                    <Button
                      className="quantityButton decrease"
                      onClick={() => setQty(-1)}
                      type="primary"
                    >
                      -1
                    </Button>
                  </Col>
                  <Col>
                    <Form.Item className="font-xl" label={t('step4Quantity')} name="quantity">
                      <InputNumber
                        min={1}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button
                      className="quantityButton increase"
                      onClick={() => setQty(1)}
                      type="primary"
                    >
                      +1
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Col>
                  <Form.Item label={t('step4Condition')} name="condition" style={{ marginTop: 30 }}>
                    <Radio.Group className="conditionBtn" onChange={e => setCondition(e.target.value)}>
                      <Radio.Button className={`default ${condition === 'ok' ? 'ok-button' : ''}`} value="ok">
                        <Barcode displayValue={false} value="condition::ok" width={1} /><br />
                        {t('step4BarcodeStatusOK')}
                      </Radio.Button>

                      <Radio.Button value="incomplete">
                        <Barcode displayValue={false} value="condition::ic" width={1} /><br />
                        {t('step4BarcodeStatusIncomplete')}
                      </Radio.Button>

                      <Radio.Button value="damaged">
                        <Barcode displayValue={false} value="condition::dmg" width={1} /><br />
                        {t('step4BarcodeStatusDamaged')}
                      </Radio.Button>

                      <Radio.Button value="damaged_incomplete">
                        <Barcode displayValue={false} value="condition::dmgic" width={1} /><br />
                        {t('step4BarcodeStatusDamagedIncomplete')}
                      </Radio.Button>

                    </Radio.Group>
                  </Form.Item>

                  {condition !== 'ok' && (
                    <Col className="infoBox conditionInfo">
                      <Col>
                        {condition === 'incomplete' && <><h3>{t('step4BarcodeStatusIncomplete')}</h3><p>{t('step4BarcodeStatusIncompleteMoreInfo')}</p></>}
                        {condition === 'damaged' && <><h3>{t('step4BarcodeStatusDamaged')}</h3><p>{t('step4BarcodeStatusDamagedMoreInfo')}</p></>}
                        {condition === 'damaged_incomplete' && <><h3>{t('step4BarcodeStatusDamagedIncomplete')}</h3><p>{t('step4BarcodeStatusDamagedIncompleteMoreInfo')}</p></>}
                      </Col>
                    </Col>
                  )}
                </Col>
                <Col>
                  <Form.Item label={t('step4MissingLabel')} name="missing_label">
                    <Radio.Group size="large">
                      <Radio.Button value={false}>{t('no')}</Radio.Button>
                      <Radio.Button value={true}>{t('yes')}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  {labelStatus &&
                    <Col className="infoBox conditionInfo">
                      <h3>{t('step4MissingInfo')}</h3>
                      <Col>
                        <p>{t('step4MissingInfoDesc')}</p>
                      </Col>
                    </Col>
                  }
                </Col>
              </Col>
            </Row>

            <Row className="cancelSection">
              <Col span={16}>
                <Form.Item className="commentSection" label={t('step4Comment')} name="comment" normalize={normalizeValue}>
                  <Input.TextArea rows={4} size="large" />
                </Form.Item>
              </Col>
              <Col>
                <NavigateButton
                  className="cancelButton"
                  label={t('cancel')}
                  loading={currentScanDetails.value.stepIsLoading.value}
                  onClick={onCancel}
                  qrAction="::loop::cancelBarcodeModal"
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

AddProductModal.propTypes = {
  isScan: PropTypes.bool,
  isVisible: PropTypes.bool,
  onCancelCallback: PropTypes.func,
  onSaveCallback: PropTypes.func,
  product: PropTypes.object,
  selectedPackage: PropTypes.object,
}

export default AddProductModal
