import PropTypes from 'prop-types'

export default PropTypes.shape({
  getFieldDecorator: PropTypes.func.isRequired,
  getFieldError: PropTypes.func.isRequired,
  getFieldsError: PropTypes.func.isRequired,
  getFieldsValue: PropTypes.func.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  isFieldsTouched: PropTypes.func.isRequired,
  isFieldTouched: PropTypes.func.isRequired,
  isFieldValidating: PropTypes.func.isRequired,
  resetFields: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
  validateFieldsAndScroll: PropTypes.func.isRequired,
})
