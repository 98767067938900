import React from 'react'
import { Col, Typography, Row } from 'antd'
import logo from '../../../_shared/assets/images/loop-blue-logo.png'

const { Title } = Typography

const HomePage = () => (
  <>
    <Row justify="center" type="flex">
      <Col>
        <img
          alt="Loop logo"
          name="homepageLogoSelenium"
          src={logo}
          style={{ height: '115px' }}
        />
      </Col>
    </Row>

    <Row justify="center" type="flex">
      <Col>
        <Title level={2} name="homepageTitleSelenium">
        Transactional Data System
        </Title>
      </Col>
    </Row>
  </>
)

export default HomePage
