import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Dropdown,
  Layout,
  message,
  Select,
  Steps,
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { routePropTypes } from '../../../_shared/types'
import { DailyCounter, DraftCounter } from '../'

import images from '../../assets/images'
import logo from '../../../_shared/assets/images/loop-blue-logo.png'

import './index.css'

import { currentScanDetails } from '../../Layout'
import translations from '../../constants/locales'

const { Option } = Select

const flags = {
  en: images.enFlag,
  fr: images.frFlag,
}

const onClick = ({ item, key }) => {
  localStorage.setItem('tds-active-app', key)
  message.info(`Switched to ${item.props.name}`)
  window.location.reload()
}

const items = [
  {
    key: 'admin',
    label: 'Transactional Data System',
    name: 'Transactional Data System',
  },
  {
    key: 'checkin',
    label: 'Warehouse Check-in System',
    name: 'Warehouse Check-in System',
  },
]

const { Sider } = Layout

const Sidebar = ({
  barcodes,
  location: { pathname },
  userHasAdminPermission,
}) => {
  const {i18n, t } = useTranslation()

  let stepElements = [
    {
      title: t('step1Title'),
    },
    {
      title: t('step2Title'),
    },
    {
      title: t('step3Title'),
    },
    {
      title: t('step4Title'),
    },
  ]

  if (currentScanDetails.value.stepIsLoading.value) {
    stepElements[currentScanDetails.value.step.value].icon = <LoadingOutlined />
  } else {
    if(stepElements[currentScanDetails.value.step.value])
      stepElements[currentScanDetails.value.step.value].icon = null
  }

  return (
    <Sider>
      <div
        className="logoWrapper"
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        <Dropdown
          disabled={!userHasAdminPermission}
          menu={{ items, onClick }}
          placement="bottom"
        >
          <img
            alt="Loop logo"
            src={logo}
            style={{
              cursor: 'pointer',
              height: '45px',
            }}
          />
        </Dropdown>
      </div>

      <div
        className="logoWrapper"
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        <Select
          className="lang-selector"
          onChange={(selected) => {localStorage.setItem('activeLocale', selected); i18n.changeLanguage(selected)}}
          value={i18n.language}
        >
          {React.Children.toArray(Object.keys(translations).map(locale =>
            <Option className="list-none" value={locale}>
              <img alt={locale} className="language-selector-image" src={flags[locale]} /> <span>{translations[locale].localeName}</span>
            </Option>
          ))}
        </Select>
      </div>

      <Steps
        current={currentScanDetails.value.step.value}
        direction="vertical"
        icon={<LoadingOutlined />}
        items={stepElements}
        status="process"
      />

      <DailyCounter />
      <DraftCounter />
    </Sider>
  )
}

Sidebar.propTypes = {
  ...routePropTypes,
  barcodes: PropTypes.object,
  userHasAdminPermission: PropTypes.bool,
}

export default withRouter(Sidebar)
