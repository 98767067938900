import { useEffect } from 'react'
import mitt from 'mitt'

import { currentScanDetails } from '../../Layout'

const events = mitt()

export const scanActions = [
  '::loop::checkQrCode',
  '::loop::goNextStep',
  '::loop::checkData',
  '::loop::finish',
  '::loop::addToList',
  '::loop::cancelBarcodeModal',
  '::loop::QtyDecrease',
  '::loop::QtyIncrease',
  '::loop::editPackage-',
  '::loop::selectCorrectStore-',
  '::loop::checkFinish',
  '::loop::goBacktoEdit',
  '::loop::finishForm',
  'condition::ok',
  'condition::ic',
  'condition::scd',
  'condition::up',
  'condition::dmg',
  'condition::dmgic',
]

class BarcodeScanner {
  inputString = ''
  initialize = () => {
    document.addEventListener('keypress', this.keyup)
  }
  close = () => document.removeEventListener('keypress', this.keyup)
  keyup = (event) => {
    if (event.key === 'Enter') {
      if (this.inputString.includes('::loop::') || this.inputString.includes('condition::')) {
        events.emit('onbarcodescaned', this.inputString)
      }

      event.preventDefault()
      event.stopPropagation()

      this.inputString = ''
    } else {
      this.inputString += event.key
    }
  }
}

const ScannerActions = (props) => {
  useEffect(() => {
    const barcode = new BarcodeScanner()

    barcode.initialize()

    return () => {
      barcode.close()
    }
  }, [])

  useEffect(() => {
    const scanHandler = code => currentScanDetails.value.barCodeAction.value = code

    events.on('onbarcodescaned', scanHandler)

    return () => {
      events.off('onbarcodescaned', scanHandler)
    }
  }, [])

  return null
}

export default ScannerActions
