import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import {
  Alert,
  Form,
  Input,
  InputNumber,
  Select,
  Modal,
} from 'antd'
import { formPropTypes, packagePropTypes } from '../../types'

const PackagesModalForm = ({
  barcodes,
  errorMessage,
  isModalLoading,
  onSubmit,
  selectedPackage,
  setModalLoading,
  setVisibility,
}) => {
  const [form] = Form.useForm()
  const { getFieldsValue, isFieldsTouched } = form
  const isUpdate = !!selectedPackage

  const { Option } = Select

  const handleOk = () => {
    const isFormUpdated = isFieldsTouched()
    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fieldsValue = getFieldsValue(['upc', 'missing_label', 'quantity', 'condition', 'comment'])
          const actionType = isUpdate ? 'UPDATE' : 'CREATE'
          const id = isUpdate ? selectedPackage.id : undefined

          onSubmit(actionType, { ...fieldsValue, id })
        })
        .catch((error) => handleError(error))
    } else {
      setVisibility(false)
    }
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  const handleCancel = () => {
    setVisibility(false)
  }

  const barcodesForSelect = Object.keys(barcodes).map(barcode => ({ label: barcode, value: barcode }))

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelPackageModalBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updatePackageSelenium' : 'addPackageSelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={handleCancel}
      onOk={handleOk}
      open
      title={isUpdate ? 'Update package' : 'Add a new package'}
    >
      <Form form={form} layout="vertical">
        {
          (isEmpty(barcodes))
            ? (
              <Form.Item
                initialValue={isUpdate ? selectedPackage.upc : undefined}
                label="Product Barcode"
                name="upc"
                rules= {[
                  {
                    message: 'Please add a barcode!',
                    required: true,
                  },
                  {
                    message: 'Barcode should contain only English letters and numbers!',
                    pattern: new RegExp('^[A-Za-z0-9]+$'),
                  },
                ]}
              >
                <Input disabled={isUpdate} />
              </Form.Item>
            )
            : (
              <Form.Item label="Product Barcode" name="upc">
                <Select
                  options={barcodesForSelect}
                  showSearch
                  size="large"
                />
              </Form.Item>
            )
        }

        <Form.Item
          initialValue={isUpdate ? `${selectedPackage.missing_label}` : 'false'}
          label="Missing label"
          name="missing_label"
          rules={[{ message: 'Please set missing label!', required: true }]}
        >
          <Select>
            <Option value="false">No</Option>
            <Option value="true">Yes</Option>
          </Select>
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedPackage.quantity : 1}
          label="Quantity"
          name="quantity"
          rules={[{ message: 'Please set the quantity!', required: true }]}
        >
          <InputNumber
            min={1}
            parser={(value) => value.split('.')[0].split(',')[0]}
          />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedPackage.condition : 'ok'}
          label="Condition"
          name="condition"
          rules={[{ message: 'Please set the condition!', required: true }]}
        >
          <Select>
            <Option value="damaged">Damaged</Option>
            <Option value="damaged_incomplete">Damaged incomplete</Option>
            <Option value="incomplete">Incomplete</Option>
            <Option value="ok">Ok</Option>
          </Select>
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedPackage.comment : undefined}
          label="Comment"
          name="comment"
        >
          <Input />
        </Form.Item>
      </Form>

      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

PackagesModalForm.propTypes = {
  barcodes: PropTypes.object,
  errorMessage: PropTypes.string,
  form: formPropTypes,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedPackage: packagePropTypes,
  setModalLoading: PropTypes.func.isRequired,
  setVisibility: PropTypes.func.isRequired,
}

export default PackagesModalForm
