import React from 'react'
import PropTypes from 'prop-types'
import { Col, message, Row } from 'antd'
import { Step1, Step2, Step3, Step4, Finish } from './steps'
import { getShippingContainer } from './api'

import { currentScanDetails } from '../../Layout'
import { scanActions } from '../../components/scanner-actions/ScannerActions'

export const normalizeValue = (value) => {
  let withoutActions = value

  scanActions.forEach(element => {
    withoutActions = withoutActions.replace(element, '')
  })

  return withoutActions
}

export const loadShippingContainer = (shippingContainerId, coms_container = null) => {
  getShippingContainer(shippingContainerId)
    .then(data => {
      currentScanDetails.value.data.value = [
        {
          code: data.partner_code,
          country: data.country,
        },
        {
          store_id: data.store.id,
        },
        {
          comment: data.comment,
          coms_container,
          condition: data.condition,
          id: data.id,
          identity_code: data.identity_code,
          isLoaded: true,
        },
      ]

      currentScanDetails.value.step.value = 3
    })
    .catch(error => {
      message.open({
        content: error.message,
        type: 'error',
      })
    })
    .finally(() => {
      currentScanDetails.value.stepIsLoading.value = false
    })
}

const ScanPage = ({ barcodes }) => {
  const steps = [
    <Step1 key="step_1" />,
    <Step2 key="step_2" />,
    <Step3 barcodes={barcodes} key="step_3" />,
    <Step4 barcodes={barcodes} key="step_4" />,
    <Finish key="finish" />,
  ]

  return (
    <Row>
      <Col span={24}>
        {steps[currentScanDetails.value.step.value]}
      </Col>
    </Row>
  )
}

ScanPage.propTypes = {
  barcodes: PropTypes.object,
}

export default ScanPage
