import queryString from 'query-string'
import { getHeaders, apiResponseHandler } from '../../../_shared/api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/stores'

export const getStores = ({
  city,
  companyFilter,
  countryFilter,
  itemsPerPage,
  name,
  ownerFilter,
  page,
  state,
  zip,
}) => {
  const queryParams = queryString.stringify(
    {
      city,
      company_id: companyFilter,
      country: countryFilter,
      items_per_page: itemsPerPage,
      name,
      owner_id: ownerFilter,
      page,
      state,
      zip,
    },
    {
      arrayFormat: 'bracket',
    },
  )
  const endpoint = `${ENDPOINT_BASE_URL}${queryParams ? `?${queryParams}` : ''}`

  return fetch(
    endpoint,
    { headers: getHeaders() },
  )
    .then(apiResponseHandler)
}

export const updateStore = ({ fields }) => fetch(
  `${ENDPOINT_BASE_URL}/${fields.id}`,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(),
    method: 'PUT',
  },
).then(apiResponseHandler)

export const createStore = ({ fields }) => fetch(
  ENDPOINT_BASE_URL,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(),
    method: 'POST',
  },
).then(apiResponseHandler)

export const deleteStore = ({ recordId }) => (
  fetch(`${ENDPOINT_BASE_URL}/${recordId}`, {
    headers: getHeaders(),
    method: 'DELETE',
  })
).then(apiResponseHandler)

export const getPartners = () => (
  fetch('/api/admin/partners', {
    headers: getHeaders(),
  })
).then(apiResponseHandler)

export const getCompanies = () => (
  fetch('/api/admin/companies', {
    headers: getHeaders(),
  })
).then(apiResponseHandler)
