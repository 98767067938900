import { getHeaders, apiResponseHandler } from '../../../_shared/api-utils'

export const createShippingContainerMedia = ({ accessTokenPromise, file }) => {
  const formData = new FormData()
  formData.append('media', file)

  return fetch(
    '/api/admin/media',
    {
      body: formData,
      headers: getHeaders(),
      method: 'POST',
    },
  ).then(apiResponseHandler)
}
