import queryString from 'query-string'
import { getHeaders, apiResponseHandler } from '../../../_shared/api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/companies'

export const getCompanies = ({
  itemsPerPage,
  name,
  page,
}) => {
  const queryParams = queryString.stringify(
    {
      items_per_page: itemsPerPage,
      name: name,
      page,
      sort_by: 'name',
    },
    {
      arrayFormat: 'bracket',
    },
  )
  const endpoint = `${ENDPOINT_BASE_URL}${queryParams ? `?${queryParams}` : ''}`

  return fetch(
    endpoint,
    { headers: getHeaders() },
  )
    .then(apiResponseHandler)
}

export const updateCompany = ({ fields }) => fetch(
  `${ENDPOINT_BASE_URL}/${fields.id}`,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(),
    method: 'PUT',
  },
).then(apiResponseHandler)

export const createCompany = ({ fields }) => fetch(
  ENDPOINT_BASE_URL,
  {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(),
    method: 'POST',
  },
).then(apiResponseHandler)

export const deleteCompany = ({ recordId }) => (
  fetch(`${ENDPOINT_BASE_URL}/${recordId}`, {
    headers: getHeaders(),
    method: 'DELETE',
  })
).then(apiResponseHandler)
