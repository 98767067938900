import React from 'react'
import { useAsync } from 'react-async'
import PropTypes from 'prop-types'
import { Button, Modal, Empty, Spin, Table } from 'antd'
import { ErrorMessage } from '../../../_shared/components'
import { localizedDate } from '../../utils'
import { getShippingContainerChangelog } from './api'

const normalizeValue = (value) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No'
  }

  return value === null || value === undefined || value === '' ? '-' : value
}

const ChangelogTable = ({ shippingContainerId }) => {
  const { data, error, isLoading } = useAsync({
    promiseFn: getShippingContainerChangelog,
    shippingContainerId,
  })

  const tableColumns = [
    {
      className: 'createdAtSelenium',
      dataIndex: 'created_at',
      render: created_at => localizedDate(created_at),
      title: 'Change Date-Time',
    },
    {
      className: 'changedItemTypeSelenium',
      dataIndex: 'item_type',
      key: 'item_type',
      title: 'Changed Item\'s type',
    },
    {
      className: 'changeTypeSelenium',
      dataIndex: 'event',
      key: 'event',
      title: 'Change Type',
    },
    {
      className: 'packageUpcsSelenium',
      dataIndex: 'object',
      render: object => (object && object.upc) ? object.upc : '-',
      title: 'Package upc',
    },
    {
      className: 'originalValueSelenium',
      dataIndex: 'object_changes',
      key: 'origin_object_value',
      render: object_changes => object_changes && Object.keys(object_changes).map((key) => {
        const value = object_changes[key][0]
        const displayValue = typeof value === 'object' ? JSON.stringify(value) : normalizeValue(value)

        return (
          <div key={`original_${key}`} style={{ marginBottom: '5px', maxWidth: '200px', minHeight: '44px' }}>
            <span><b>{key}:</b>&nbsp;</span>
            <span>{displayValue}</span>
          </div>
        )
      }),
      title: 'Original Value',
    },
    {
      className: 'newValueSelenium',
      dataIndex: 'object_changes',
      key: 'new_object_value',
      render: object_changes => object_changes && Object.keys(object_changes).map((key) => {
        const value = object_changes[key][1]
        const displayValue = typeof value === 'object' ? JSON.stringify(value) : normalizeValue(value)

        return (
          <div key={`new_${key}`} style={{ marginBottom: '5px', maxWidth: '200px', minHeight: '44px' }}>
            <span><b>{key}:</b>&nbsp;</span>
            <span>{displayValue}</span>
          </div>
        )
      }),
      title: 'New Value',
    },
    {
      dataIndex: 'author',
      key: 'author',
      title: 'Changed by',
    },
  ]

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data} />

  if (isLoading) return (
    <Spin name="loadingSpinnerSelenium" tip="Loading...">
      <div className="content" />
    </Spin>
  )

  return (
    <Table
      bordered
      className="changelogTableSelenium"
      columns={tableColumns}
      dataSource={data || []}
      loading={isLoading}
      locale={{ emptyText: <Empty description="No history" /> }}
      pagination={false}
      rowKey="id"
      style={{ overflowX: 'scroll' }}
    />
  )
}

ChangelogTable.propTypes = {
  shippingContainerId: PropTypes.string.isRequired,
}

const ChangelogModal = ({ setVisibility, shippingContainerId }) => (
  <Modal
    footer={[
      <Button key="back" onClick={() => setVisibility(false)}>
        Close
      </Button>,
    ]}
    onCancel={() => setVisibility(false)}
    open
    title="Changelog"
    width="80%"
  >
    <ChangelogTable shippingContainerId={shippingContainerId} />
  </Modal>
)

ChangelogModal.propTypes = {
  setVisibility: PropTypes.func.isRequired,
  shippingContainerId: PropTypes.string.isRequired,
}

export default ChangelogModal
